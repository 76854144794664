/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { Container, MenuItem, Select, Button, Divider, TextField, TextareaAutosize, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { API_URL } from 'src/utils/constants';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { formatCurrency } from 'src/utils/formatCurrency';
import PropTypes from 'prop-types'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import axiosMB from 'src/utils/axios/interceptors';
import { AddNote, Notes } from '../Bookings/SingleBooking';
import moment from 'moment';

const BookingDetailsPage = () => {
  const { id: bookingId } = useParams();
  const [booking, setBooking] = useState({});
  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateError, setDateError] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateBooking = async (changes) => {
    const { id: bookingId, changedData: updateFields } = changes
    try {
      const response = await axiosMB.patch(
        `${API_URL}/admin/mb/bookings/${bookingId}`,
        { updateFields },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        location.reload();
      }
    } catch (error) {
      console.error('Failed to update booking finance:', error);
    }
  };

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        setIsLoading(true);
        // Replace 'your_api_endpoint' with the actual API endpoint.
        const response = await fetch(
          `${API_URL}/admin/mb/bookings/${bookingId}`
        );
        const data = await response.json();
        setBooking(data);
        setIsLoading(false);

      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };

    const fetchPartner = async () => {
      try {
        const _partners = await fetch(`${API_URL}/admin/mb/partners`);
        const partners = await _partners.json();
        setPartners(partners);
      } catch (error) {
        console.error('Failed to fetch partners:', error);
      }
    };

    fetchBooking();
    fetchPartner();
  }, []);

  const handleBookingFinanceUpdate = async () => {
    const response = await updateBooking({ id, changedData });
    // const data = await response.json();
  };

  const handleUpdateBookingDate = async (type) => {
    const startDateValue = type === 'Start Date' ? new Date(newValue).getTime() : new Date(startDate).getTime();
    const endDateValue = type === 'End Date' ? new Date(newValue).getTime() : new Date(endDate || new Date()).getTime();

    if (type === 'Start Date' && startDateValue > endDateValue) {
      setDateError('Start date cannot be greater than end date');
      return;
    }

    if (type === 'End Date' && endDateValue < startDateValue) {
      setDateError('End date cannot be less than start date');
      return;
    }

    if (type === 'Start Date') {
      await updateBooking({ id, changedData: { startDate: new Date(newValue) } });
    } else if (type === 'End Date') {
      await updateBooking({ id, changedData: { endDate: new Date(newValue) } });
    } else if (type === 'Lead Date') {
      await updateBooking({ id, changedData: { leadDate: new Date(newValue) } });
    }
    // const data = await response.json();
  };

  const handleAllocatePartner = async () => {
    setIsLoading(true);
    try {
      const response = await axiosMB.patch(
        `${API_URL}/admin/mb/bookings/${bookingId}/partner`,
        { partnerId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        location.reload();
      }
    } catch (error) {
      console.error('Failed to allocate partner:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    id,
    activityTitle,
    orderId,
    partner,
    isPartnerAllocated,
    isPartnerAssignedTo,
    partnerAssignedTo,
    guest,
    amountFinal,
    amountDiscount,
    amountAdvance,
    amountTotal,
    status,
    internalStatus,
    amountPartnerSettlementRecommended,
    amountPartnerSettlementActual,
    amountPartnerSettlementLeft,
    amountPartnerAdvancePaid,
    amountPartnerToBeCollected,
    amountPartnerCollected,
    date,
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    notesInternal,
    notesPartner,
    notesGuest,
    updateHistory,
    bookingsSummary,
    leadDate,
    assignedAssets,
    partnerMargin
  } = booking;
  const [editField, setEditField] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [updatePartnerFinance, setUpdatePartnerFinance] = useState(false);
  const [financeChanges, setFinanceChanges] = useState({ oldFinances: {}, newFinances: {} });
  const [changedData, setChangedData] = useState({});
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [addNoteType, setAddNoteType] = useState('');
  const [changePartner, setChangePartner] = useState(false);
  const handleNoteOpen = () => { setAddNoteOpen(true) };
  const handleValueChange = (e) => {
    const { value } = e.target;
    setNewValue(value.trim().replace(/[^0-9]/g, ''))
  };
  const HandleDateChange = (e) => {
    const { value } = e.target;
    setNewValue(value)
  };

  useEffect(() => {
    const guestName = booking?.guest?.name?.split('').slice(0, 4).join('')
    document.title = guestName + ' - ' + booking?.activityTitle;
  }, [booking]);

  useEffect(() => {
    const originalFinances = {
      // Customer finances
      total: amountTotal,
      discount: amountDiscount,
      final: amountFinal,
      advance: amountAdvance,
      // Partner finances
      recommended: isPartnerAllocated ? amountPartnerSettlementRecommended : undefined,
      margin: isPartnerAllocated ? partnerMargin ? partnerMargin : (((amountPartnerSettlementRecommended - amountPartnerSettlementActual) / amountPartnerSettlementRecommended) * 100).toFixed(2) : 0,
      actual: isPartnerAllocated ? amountPartnerSettlementActual : undefined,
      advanceToPartner: isPartnerAllocated ? amountPartnerAdvancePaid : undefined,
      "to be Collected": isPartnerAllocated ? amountPartnerToBeCollected : undefined,
      collected: isPartnerAllocated ? amountPartnerCollected : undefined,
      settlement: isPartnerAllocated ? amountPartnerSettlementLeft : undefined
    }
    switch (editField) {
      case 'Total':
        if (isPartnerAllocated && updatePartnerFinance) {
          const newAmountFinal = Number(newValue - amountDiscount);
          const newToBeCollected = Number(newAmountFinal - amountAdvance)
          const newRecommended = Number(newValue) * 0.8
          const newPartnerSettlement = amountPartnerSettlementActual - amountPartnerAdvancePaid - newToBeCollected
          const changedFinances = { total: Number(newValue), final: newAmountFinal, "to be Collected": newToBeCollected, recommended: newRecommended, settlement: newPartnerSettlement }
          const newFinances = { ...originalFinances, ...changedFinances }
          setChangedData(financeChangeMapper(changedFinances))
          setFinanceChanges({ oldFinances: originalFinances, newFinances })
        } else {
          const newAmountFinal = Number(newValue - amountDiscount);
          const changedFinances = { total: Number(newValue), final: newAmountFinal }
          const newFinances = { ...originalFinances, ...changedFinances }
          setChangedData(financeChangeMapper(changedFinances))
          setFinanceChanges({ oldFinances: originalFinances, newFinances })
        }
        break;
      case 'Discount':
        if (isPartnerAllocated && updatePartnerFinance) {
          const newAmountFinal = Number(amountTotal - newValue);
          const newToBeCollected = newAmountFinal - amountAdvance
          const newPartnerSettlement = amountPartnerSettlementActual - amountPartnerAdvancePaid - newToBeCollected
          const changedFinances = { discount: Number(newValue), final: newAmountFinal, "to be Collected": newToBeCollected, settlement: newPartnerSettlement }
          const newFinances = { ...originalFinances, ...changedFinances }
          setChangedData(financeChangeMapper(changedFinances))
          setFinanceChanges({ oldFinances: originalFinances, newFinances })
        } else {
          const newAmountFinal = Number(amountTotal - newValue);
          const changedFinances = { discount: Number(newValue), final: newAmountFinal }
          const newFinances = { ...originalFinances, ...changedFinances }
          setChangedData(financeChangeMapper(changedFinances))
          setFinanceChanges({ oldFinances: originalFinances, newFinances, })
        }
        break;

      case 'Advance': if (isPartnerAllocated && updatePartnerFinance) {
        const newToBeCollected = Number(amountFinal - newValue);
        const newPartnerSettlement = amountPartnerSettlementActual - newToBeCollected
        const changedFinances = { advance: Number(newValue), "to be Collected": newToBeCollected, settlement: newPartnerSettlement }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances });
      } else {
        const changedFinances = { advance: Number(newValue) }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances });

      }
        break;
      // Partner finances
      case 'Recommended': {
        const newAmountActual = (newValue * (100 - originalFinances.margin)) / 100
        console.log(originalFinances.margin)
        const newPartnerSettlement = Number(newAmountActual - amountPartnerAdvancePaid - amountPartnerToBeCollected);

        const changedFinances = { recommended: Number(newValue), actual: newAmountActual, settlement: newPartnerSettlement, margin: originalFinances.margin }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances })
        break;
      }
      case 'Margin': {
        const newAmountActual = (originalFinances.recommended * (100 - newValue)) / 100
        const newPartnerSettlement = Number(newAmountActual - amountPartnerAdvancePaid - amountPartnerToBeCollected);
        const changedFinances = { margin: Number(newValue), actual: newAmountActual, settlement: newPartnerSettlement }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances })
        break;
      }
      case 'Actual': {
        const newPartnerMargin = ((originalFinances.recommended - newValue) / originalFinances.recommended) * 100
        const newPartnerSettlement = Number(newValue - amountPartnerAdvancePaid - amountPartnerToBeCollected);
        const changedFinances = { actual: Number(newValue), margin: newPartnerMargin.toFixed(2), settlement: newPartnerSettlement }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances })
        break;
      }
      case 'PartnerAdvance': {
        const newPartnerSettlement = Number(originalFinances.settlement - newValue);
        const changedFinances = { advanceToPartner: Number(newValue), settlement: newPartnerSettlement }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances })
        break;
      }
      case 'Collected': {
        const changedFinances = { collected: Number(newValue) }
        const newFinances = { ...originalFinances, ...changedFinances }
        setChangedData(financeChangeMapper(changedFinances))
        setFinanceChanges({ oldFinances: originalFinances, newFinances })
        break;
      }

      default:
        break;
    }
  }, [updatePartnerFinance, newValue]);

  const isCancelled = status?.toLowerCase().startsWith('cancelled');

  return (
    <Page>
      <Container>
        {id === null || isLoading ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className='flex flex-col justify-start gap-8'>
              {/* Order section */}
              <div className='flex justify-between items-center rounded-lg shadow-md p-4'>
                <div className='flex items-center justify-start gap-8 w-full'>
                  <LabelWithContent label={"Title"} content={activityTitle} />
                  <LabelWithContent label={"Order"} content={`#${orderId}`} />
                  <LabelWithContent label={"Guest"} content={guest?.name} />
                  <LabelWithContent label={"Date"} content={date} />
                  <div className='ml-auto flex items-center gap-8'>
                    {!(activityTitle?.includes('Taxi Tour')) && !isCancelled && !(status === 'completed-settlement-done' || status === 'completed') && <button
                      onClick={() => {
                        window.open(`/dashboard/edit-booking-variants/${id}`, '_blank');
                      }}
                      className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 px-2 rounded-lg whitespace-nowrap'>
                      Edit Variants
                    </button>}
                    <LabelWithContent className={'whitespace-nowrap'} label={"Lead Date"} content={leadDate ? moment(leadDate).format('MMM, DD') : 'Not set'}
                      editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen();
                        setEditField('Lead Date');
                        setNewValue(leadDate?.split('T')[0] || moment().format('YYYY-MM-DD'))
                      }}
                    />
                    <div className='flex items-center gap-4'>
                      <LabelWithContent label={"Phone"} content={guest?.phone} />
                      <WhatsAppIcon />
                    </div>
                  </div>
                </div>
              </div>
              {/* Booking section */}
              <div className='flex flex-col justify-start gap-2 rounded-lg shadow-md p-4'>
                <div className='flex items-center justify-start gap-10'>
                  <LabelWithContent label={"Booking"} content={`#${id}`} />
                  <LabelWithContent label={"Start Date"} content={startDateLabel}
                    editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                    onClick={() => {
                      handleOpen();
                      setEditField('Start Date');
                      setNewValue(startDate.split('T')[0])
                    }}
                  />
                  <LabelWithContent label={"End Date"} content={endDateLabel}
                    editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                    onClick={() => {
                      handleOpen();
                      setEditField('End Date');
                      setNewValue(endDate ? endDate.split('T')[0] : new Date().toISOString().split('T')[0])
                    }}
                  />
                  <LabelWithContent label={"Status"} content={status} />
                  {/* <LabelWithContent label={"Internal Status"} content={internalStatus} /> */}
                  {status === "confirmed" ? (
                    <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                      onClick={async () => {
                        await updateBooking({ id: bookingId, changedData: { status: 'completed' } })
                      }}>
                      Complete Booking
                    </Button>
                  ) :
                    status === "completed" ? (
                      <div className='flex gap-6 items-center'>
                        <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap'
                          onClick={async () => {
                            await updateBooking({ id: bookingId, changedData: { status: 'partner-settlement-acknowledgement-pending' } })
                          }}>
                          Acknowledgement required
                        </Button>
                        <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap'
                          onClick={async () => {
                            await updateBooking({ id: bookingId, changedData: { status: 'partner-settlement-pending' } })
                          }}>
                          Acknowledgement not required
                        </Button>
                      </div>
                    ) : status === "partner-settlement-pending" ? (
                      <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap'
                        onClick={async () => {
                          await updateBooking({ id: bookingId, changedData: { status: 'completed-settlement-done' } })
                        }}>
                        Complete Settlement
                      </Button>
                    )
                      :
                      <></>
                  }

                  {
                    !isCancelled &&
                    status !== 'completed-settlement-done' &&
                    status !== 'completed' &&
                    <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                      onClick={() => { setCancelOpen(true) }}>
                      {status === 'cancelled-refund-pending' ? 'Handle Refund' : 'Cancel Booking'}
                    </Button>}
                  {
                    isCancelled &&
                    status === 'cancelled-refund-pending' &&
                    <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                      onClick={() => { setCancelOpen(true) }}>
                      Handle Refund
                    </Button>}
                </div>
                <Divider orientation="horizontal" flexItem />
                <div className='flex items-center justify-start gap-10'>
                  <LabelWithContent label={"Total"} content={formatCurrency(amountTotal)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                    onClick={() => {
                      handleOpen()
                      setEditField('Total')
                      setNewValue(amountTotal)
                    }}
                  />
                  <LabelWithContent label={"Discount"} content={formatCurrency(amountDiscount)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                    onClick={() => {
                      handleOpen()
                      setEditField('Discount')
                      setNewValue(amountDiscount)
                    }}
                  />
                  <LabelWithContent label={"Final"} content={formatCurrency(amountFinal)} />
                  <LabelWithContent label={"Advance"} content={formatCurrency(amountAdvance)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                    onClick={() => {
                      handleOpen()
                      setEditField('Advance')
                      setNewValue(amountAdvance)
                    }}
                  />
                </div>
                <div className='flex flex-col items-start justify-center mt-5'>
                  <h3 className='whitespace-nowrap font-bold'>Summary:</h3>
                  <p className='font-semibold'>{bookingsSummary}</p>
                </div>
                {/* Partner section */}
                {!isCancelled && (!booking.isPartnerAllocated || changePartner) && (
                  <div className='flex items-center justify-start gap-10'>
                    <h3 className='whitespace-nowrap'>Partner</h3>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Age"
                      className='max-w-[400px]'
                      fullWidth
                      onChange={(event) => {
                        setPartnerId(event.target.value);
                      }}
                    >
                      {partners.map((partner) => (
                        <MenuItem key={partner.id} value={partner.id}>{partner.title}</MenuItem>
                      ))}
                    </Select>
                    <Button
                      className="w-full max-w-[120px] font-extralight bg-[#FF455B] hover:bg-[#f36575] text-white"
                      disabled={isLoading}
                      onClick={handleAllocatePartner}
                    >
                      SAVE
                    </Button>
                  </div>
                )}
              </div>
              {isPartnerAllocated !== null && isPartnerAllocated === true && (
                <div className='flex flex-col justify-between items-start rounded-lg shadow-md p-4 gap-2'>
                  <div className='flex items-center justify-start gap-8'>
                    <LabelWithContent label={"Partner Id"} content={`#${partner.id}`} />
                    <LabelWithContent label={"Partner Name"} content={partner.name} />
                    <LabelWithContent label={"Partner Phone"} content={partner.phone} />
                    {(booking.isPartnerAllocated &&
                      !changePartner &&
                      status !== "completed-settlement-done" &&
                      !status.startsWith("cancelled-")
                    ) && <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                      onClick={async () => { setChangePartner(true) }}>
                        Change partner
                      </Button>}
                    {booking.isPartnerAllocated && changePartner && <button className='border border-[#FF455B] hover:bg-[#da4848]/10 text-[#FF455B] w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap px-2'
                      onClick={async () => { setChangePartner(false) }}>
                      Cancel change partner
                    </button>}
                  </div>
                  <Divider flexItem className='col-span-full' orientation="horizontal" />
                  <div className='flex items-center justify-start gap-8'>
                    <LabelWithContent
                      label={"PartnerB2C"}
                      content={formatCurrency(amountPartnerSettlementRecommended)}
                      editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen()
                        setEditField('Recommended')
                        setNewValue(amountPartnerSettlementRecommended)
                      }}
                    />
                    <LabelWithContent
                      label={"Margin"}
                      content={`${partnerMargin} %`}
                      editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen()
                        setEditField('Margin')
                        setNewValue(partnerMargin)
                      }}
                    />
                    <LabelWithContent label={"Actual"} content={formatCurrency(amountPartnerSettlementActual)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen()
                        setEditField('Actual')
                        setNewValue(amountPartnerSettlementActual)
                      }}
                    />
                    <LabelWithContent label={"To Be Collected"} content={formatCurrency(amountPartnerToBeCollected)} />
                    <LabelWithContent label={"Advance"} content={formatCurrency(amountPartnerAdvancePaid)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen()
                        setEditField('PartnerAdvance')
                        setNewValue(amountPartnerAdvancePaid)
                      }} />

                    <LabelWithContent label={"Collected"} content={formatCurrency(amountPartnerCollected)} editable={!isCancelled && status !== 'completed-settlement-done' ? true : false}
                      onClick={() => {
                        handleOpen()
                        setEditField('Collected')
                        setNewValue(amountPartnerCollected)
                      }}
                    />
                    <LabelWithContent label={"Settlement"} content={formatCurrency(amountPartnerSettlementLeft)} />
                  </div>
                  {isPartnerAssignedTo !== null && isPartnerAssignedTo === true && (
                    <>
                      <Divider flexItem className='col-span-full' orientation="horizontal" />
                      <div className='flex items-center justify-start gap-8'>
                        <h1 className='font-bold self-start'>Employee</h1>
                        <LabelWithContent className={'capitalize'} label={"Assigned Employee"} content={partnerAssignedTo.name} />
                        <LabelWithContent className={'capitalize'} label={"Employee Phone"} content={partnerAssignedTo.phone} />
                      </div>
                    </>
                  )}
                  {assignedAssets && assignedAssets.length > 0 && (
                    <>
                      <Divider flexItem className='col-span-full' orientation="horizontal" />
                      <div className='flex items-center justify-start gap-8'>
                        <h1 className='font-bold self-start'>Assets</h1>
                        {assignedAssets.map((asset) => (
                          <div className='flex items-center justify-start gap-8'>
                            <LabelWithContent className={'capitalize'} label={"Brand"} content={asset.brand} />
                            <LabelWithContent className={'capitalize'} label={"Model"} content={asset.model || 'N/A'} />
                            <LabelWithContent className={'capitalize'} label={"Name"} content={asset.name} />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
              <AddNote
                bookingId={bookingId}
                noteType={addNoteType}
                allNotes={{ notesInternal: notesInternal || [], notesPartner: notesPartner || [], notesGuest: notesGuest || [] }}
                isOpen={addNoteOpen}
                onAddNote={updateBooking}
                onClose={() => { setAddNoteOpen(false) }}
              />
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                <div className='flex flex-col justify-start gap-2 rounded-lg shadow-md p-4'>
                  <div className='flex items-center justify-between'>
                    <h1 className='font-bold text-xl'>Internal Notes</h1>
                    {!isCancelled && status !== 'completed-settlement-done' && <button
                      onClick={() => {
                        handleNoteOpen()
                        setAddNoteType('internal')
                      }}
                      className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                      Note +
                    </button>}
                  </div>
                  {notesInternal?.map((note) => (<Notes key={note.createdAt} note={note} />))}
                </div>
                <div className='flex flex-col justify-start gap-2 rounded-lg shadow-md p-4'>
                  <div className='flex items-center justify-between'>
                    <h1 className='font-bold text-xl'>Partner Notes</h1>
                    {!isCancelled && status !== 'completed-settlement-done' && <button
                      onClick={() => {
                        handleNoteOpen()
                        setAddNoteType('partner')
                      }}
                      className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                      Note +
                    </button>}
                  </div>
                  {notesPartner?.map((note) => (<Notes key={note.createdAt} note={note} />))}
                </div>
                <div className='flex flex-col justify-start gap-2 rounded-lg shadow-md p-4'>
                  <div className='flex items-center justify-between'>
                    <h1 className='font-bold text-xl'>Customer Notes</h1>
                    {!isCancelled && status !== 'completed-settlement-done' && <button
                      onClick={() => {
                        handleNoteOpen()
                        setAddNoteType('customer')
                      }}
                      className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg whitespace-nowrap'>
                      Note +
                    </button>}
                  </div>
                  {notesGuest?.map((note) => (<Notes key={note.createdAt} note={note} />))}
                </div>
              </div>
              {updateHistory?.length > 0 && <div className='flex flex-col gap-4'>
                <h6 className='text-gray-400 text-sm'>Update History{` (${updateHistory?.length})`}</h6>
                {updateHistory.map((updateHistory) => (<UpdateHistory key={updateHistory._id} updateHistory={updateHistory} />)).reverse()}
              </div>}
              <Modal
                open={open}
                onClose={() => {
                  setChangedData({})
                  setEditField(null)
                  setNewValue(null)
                  setUpdatePartnerFinance(false)
                  handleClose()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  className='absolute flex flex-col py-6 p-4 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] min-h-[300px]'
                >
                  {editField !== 'Start Date' && editField !== 'End Date' && editField !== 'Lead Date' ?
                    <>
                      <div className='flex items-center gap-4'>
                        <h6 className='text-gray-400 text-xs'>{editField}</h6>
                        <input type="text" value={newValue} onChange={handleValueChange} />
                      </div>
                      <div className='flex items-center gap-4 m-0'>
                        <input value={updatePartnerFinance} onChange={() => { setUpdatePartnerFinance(prev => !prev) }} type="checkbox" />
                        <h6 className='text-gray-400 text-sm'>Update partner finances</h6>
                      </div>
                      <button
                        onClick={handleBookingFinanceUpdate}
                        className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg'>Save</button>
                      <div className='flex flex-col gap-2'>
                        <div className='grid grid-cols-3 place-items-start gap-2 space-x-6'>
                          <p></p>
                          <p className='text-gray-400'>Original</p>
                          <p>New Value</p>
                        </div>
                        {Object.keys(financeChanges.oldFinances).map((item, index) => {
                          return (<div key={item} className='grid grid-cols-3 place-items-start gap-4 space-x-6'>
                            <p className='capitalize text-gray-400 text-sm'>{item === "advanceToPartner" ? "Advance" : item}</p>
                            <p className='text-gray-400'> {item === 'margin' ? `${financeChanges.oldFinances[item]}%` : formatCurrency(financeChanges.oldFinances[item])}</p>
                            <p className='whitespace-nowrap'> {item === 'margin' ? `${financeChanges.newFinances[item]}%` : formatCurrency(financeChanges.newFinances[item])} </p>
                            {(index === 3 || index === 8) && <Divider flexItem className='col-span-full' orientation="horizontal" />}
                          </div>)
                        })}
                      </div>
                    </> : (
                      <>
                        <div className='flex items-center gap-4'>
                          <div className='flex items-center gap-4'>
                            <h6 className='text-gray-400 text-xs'>{editField}</h6>
                            <input type="date" value={newValue} onChange={HandleDateChange} />
                          </div>
                          <button
                            onClick={() => { handleUpdateBookingDate(editField) }}
                            className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[100px] font-extralight p-1.5 rounded-lg'>Save</button>
                        </div>
                        {dateError && <p className='text-red-500 text-sm'>{dateError}</p>}
                      </>
                    )
                  }
                </Box>
              </Modal>
              <CancelBooking refundPending={status === 'cancelled-refund-pending'} bookingId={bookingId} isOpen={cancelOpen} onClose={() => { setCancelOpen(false) }} onCancelBooking={updateBooking} />
            </div>
          </>
        )}
      </Container>
    </Page>
  );
};

const CancelBooking = ({ bookingId, refundPending, isOpen, onClose, onCancelBooking }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Modal
        open={isOpen} onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='absolute flex flex-col py-6 p-8 bg-white rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <h1 className='font-bold text-xl'>Cancel Booking</h1>
          <div className='flex gap-12 p-10'>
            {!refundPending && <div className='flex flex-col gap-2'>
              <h1 className='font-bold whitespace-nowrap'>Refund Pending</h1>
              <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                onClick={async () => {
                  await onCancelBooking({ id: bookingId, changedData: { status: 'cancelled-refund-pending' } })
                }}>
                Refund pending
              </Button>
            </div>}
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold whitespace-nowrap'>Refund Processed</h1>
              <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                onClick={async () => {
                  await onCancelBooking({ id: bookingId, changedData: { status: 'cancelled-refund-processed' } })
                }}>
                Process refund
              </Button>
            </div>
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold whitespace-nowrap'>Refund Adjusted</h1>
              <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                onClick={async () => {
                  await onCancelBooking({ id: bookingId, changedData: { status: 'cancelled-refund-adjusted' } })
                }}>
                Adjust refund
              </Button>
            </div>
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold whitespace-nowrap'>Cancel and No Refund</h1>
              <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                onClick={async () => {
                  await onCancelBooking({ id: bookingId, changedData: { status: 'cancelled-no-refund' } })
                }}>
                No refund
              </Button>
            </div>
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold whitespace-nowrap'>Cancel partial refund</h1>
              <Button className='bg-[#FF455B] hover:bg-[#da4848] text-white w-full max-w-[200px] font-extralight p-1.5 rounded-lg whitespace-nowrap'
                onClick={async () => {
                  await onCancelBooking({ id: bookingId, changedData: { status: 'cancelled-partial-refund' } })
                }}>
                Partial refund
              </Button>
            </div>
          </div>
        </Box>
      </Modal >
    </>
  )
}

CancelBooking.propTypes = {
  refundPending: PropTypes.bool,
  bookingId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelBooking: PropTypes.func.isRequired,
};

function LabelWithContent({ label, content, editable, onClick, className }) {
  return (
    <div className='flex flex-col '>
      <h6 className='text-gray-400 text-xs'>{label}</h6>
      <div className={`flex items-center justify-start ${editable ? 'gap-1' : 'gap-0'}`}>
        <h2 className={className}>{content}</h2>
        {editable && <CreateOutlinedIcon className='cursor-pointer' onClick={onClick} fontSize='35px' />}
      </div>

    </div>
  );
}

const financeChangeMapper = (values) => {
  const financeMap = {
    total: "amountTotal",
    discount: "amountDiscount",
    final: "amountFinal",
    advance: "amountAdvance",
    margin: "partnerMargin",
    recommended: "amountPartnerSettlementRecommended",
    actual: "amountPartnerSettlementActual",
    advanceToPartner: "amountPartnerAdvancePaid",
    "to be Collected": "amountPartnerToBeCollected",
    collected: "amountPartnerCollected",
    settlement: "amountPartnerSettlementLeft"
  }
  const mappedFinances = {}
  Object.keys(values).forEach((key) => {
    mappedFinances[financeMap[key]] = values[key]
  })
  return mappedFinances
}

const RecursivelyRenderObject = (object) => {
  if (typeof object === 'string' || typeof object === 'number' || object === null) return object;
  return Object.keys(object).map((key, index) => {
    if (typeof object[key] === 'object') {
      if (object[key] === null) return "null";
      return <RecursivelyRenderObject key={index} object={object[key]} />
    }
    return <p key={index}>{`${key}: ${object[key]}`}</p>
  })
}

const UpdateHistory = ({ updateHistory }) => {
  const { changes, updatedAt, updatedBy, _id } = updateHistory;
  const { name } = updatedBy
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const changedFields = changes.length;

  return (
    <div className='flex flex-col gap-2'>
      <ListItemButton className='bg-gray-200' onClick={handleClick}>
        <ListItemIcon className='flex items-center justify-between min-w-full '>
          <p>Updated at: {fDateTimeSuffix(updatedAt)}</p>
          <p>{name}</p>
          <p>{`(${changedFields})`}</p>
        </ListItemIcon>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton>
            <div className='grid grid-cols-3 w-full font-bold'>
              <ListItemIcon>
                <p>Field Name </p>
              </ListItemIcon>
              <p className='font-bold'>Old Value</p>
              <p className='font-bold'>New Value</p>
            </div>
          </ListItemButton>
          {changes.map((change, index) => {
            const { fieldName, oldValue, newValue } = change;
            if (fieldName?.startsWith('notes')) {
              return <ListItemButton  >
                <div className='grid grid-cols-3 w-full'>
                  <ListItemIcon>
                    <p>{fieldName}: </p>
                  </ListItemIcon>
                  <ListItemText primary={"Change in notes."} />
                  <ListItemText primary={"Change in notes."} />
                </div>
              </ListItemButton>
            };
            return (
              <ListItemButton key={index} >
                <div className='grid grid-cols-3 w-full'>
                  <ListItemIcon>
                    <p>{fieldName}: </p>
                  </ListItemIcon>
                  <ListItemText primary={RecursivelyRenderObject(oldValue?.value || "null")} />
                  <ListItemText primary={RecursivelyRenderObject(newValue?.value || "null")} />
                </div>
              </ListItemButton>
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}

export default BookingDetailsPage;
