import React, { useState, useEffect, useMemo } from 'react';
import Page from '../../components/Page';
import {
  Autocomplete,
  // Button,
  Container,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { formatCurrency } from 'src/utils/formatCurrency';
import { listBookingStatusColors, listBookingStatusLabels } from 'src/utils/statuses/bookings';
import moment from 'moment';
const filters = [
  { key: "all", value: "All", color: '#0f0f0f' },
  { key: "upcoming", value: "Upcoming", color: '#0f0f0f' },
  { key: "ongoing", value: "Ongoing", color: '#0f0f0f' },
  { key: "completed", value: "Completed", color: '#0f0f0f' },
  { key: "done", value: "Done", color: '#0f0f0f' },
]
const sortByFilters = [
  { key: 'date', value: "Created At" },
  { key: 'startDate', value: "Start Date" },
  { key: 'endDate', value: "End Date" },
]
const sortByDirection = [
  { key: 'asc', value: "Ascending" },
  { key: 'dec', value: "Descending" },
]
const opsStatus = [
  { key: '#ffffff', value: "All" },
  { key: '#F44336', value: "Action Rquired" },
  { key: '#FFA500', value: "No Action Rquired" },
  { key: '#4CAF50', value: "Completed" },
]

const ListBookingsPage = () => {
  const defalutFilter = localStorage.getItem("actionFIlter") ? localStorage.getItem("actionFIlter") : "#ffffff";
  const defalutTab = localStorage.getItem("tab") ? localStorage.getItem("tab") : "all";
  const [bookings, setBookings] = useState([]);
  const [active, setActive] = useState(defalutTab);
  const [sortBy, setSortBy] = useState("date");
  const [sortDirection, setSortDirection] = useState("dec");
  const [selctedCustomer, setSelctedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [actionFilter, setActionFilter] = useState(defalutFilter);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${API_URL}/admin/mb/bookings`);
        const data = await response.json();
        setBookings(data);
        const customerPhoneArray = Array(...new Set(data.map(booking => booking.guestPhone || "NA")));
        setCustomers(customerPhoneArray);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };

    fetchBookings();
  }, []);
  const sortDirectionFunction = (a, b) => {
    if (sortDirection == "asc") {
      return new Date(a[sortBy]) - new Date(b[sortBy])
    }
    return new Date(b[sortBy]) - new Date(a[sortBy])
  }

  const actionFilterFunction = (a) => {
    if (actionFilter == "#ffffff") {
      return a
    }
    if (actionFilter == "#F44336") {
      return a.status == "processing" || a.status == "confirmation-pending" || a.status == "confirmed-and-partner-action-pending" ? a : null
    }
    if (actionFilter == "#FFA500") {
      return a.status == "confirmed" || a.status == "completed" || a.status == "cancelled-refund-pending" ? a : null
    }
    if (actionFilter == "#4CAF50") {
      return a.status == "completed-settlement-done" || a.status == "cancelled-refund-processed" || a.status == "cancelled-refund-adjusted" || a.status == "cancelled-no-refund" ? a : null
    }
  }

  const adminBookings = useMemo(() => {
    let filteredBookings = []
    if (active == "all") filteredBookings = bookings
    if (active == "upcoming") filteredBookings = bookings.filter((booking) => moment(booking.startDate).isAfter(moment().startOf('day')))
    if (active == "ongoing") filteredBookings = bookings.filter((booking) => {
      return moment(booking.startDate).startOf('day').isSameOrBefore(moment().startOf('day')) &&
        moment(booking.endDate).startOf('day').isSameOrAfter(moment().startOf('day'))
    })
    if (active == "completed") filteredBookings = bookings.filter(booking => {
      return moment(booking.endDate).add(1, 'days').isBefore(moment().startOf('day')) &&
        !(booking.status == "completed-settlement-done" || booking.status.includes("cancel"))
    })
    if (active == "done") filteredBookings = bookings.filter(booking => booking.status == "completed-settlement-done" || booking.status.includes("cancel"))

    return filteredBookings
      .filter(booking => selctedCustomer ? booking.guestPhone == selctedCustomer : true)
      .filter(actionFilterFunction)
      .sort(sortDirectionFunction)
      .map((booking) => <AdminBookingCard key={booking.id} booking={booking} />)


  }, [sortBy, sortDirection, bookings, active, selctedCustomer, actionFilter])

  const handleSortBy = (e) => {
    setSortBy(e.target.value)
  }
  const handleActionFilter = (e) => {
    localStorage.setItem("actionFIlter", e.target.value)
    setActionFilter(e.target.value)

  }

  useEffect(() => {
    switch (active) {
      case "all":
        setSortBy("date")
        setSortDirection("dec")
        break;
      case "upcoming":
        setSortBy("startDate")
        setSortDirection("asc")
        break;
      case "ongoing":
        setSortBy("endDate")
        setSortDirection("asc")
        break;
      default:
        break;
    }
  }, [active])

  return (
    <Page title='Bookings'>
      <Container className='flex flex-col gap-4'>
        <div className='flex items-center justify-start gap-4'>
          {filters.map(item => <button
            style={active === item.key ? {
              color: 'white',
              backgroundColor: item.color
            } :
              {
                color: item.color,
                border: '2px solid ' + item.color,
              }}
            key={item.key}
            className={`px-4 py-2 rounded-lg w-40 text-center h-10 capitalize`}
            onClick={() => {
              localStorage.setItem("tab", item.key)
              setActive(item.key)
            }
            }
          >
            {item.key}
          </button>)}
        </div>
        <div className='flex items-center justify-start space-x-8'>
          <div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={customers || []}
              onChange={(e, value) => setSelctedCustomer(value)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Phone Number" />}
            />
          </div>
          <label className='font-bold m-0'>Sort By:</label>
          <Select labelId="activity-name-label" name="sortBy" value={sortBy} label="Sort By" onChange={handleSortBy}>
            {sortByFilters.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
          <Select labelId="activity-name-label" name="sortByDirection" value={sortDirection} label="Sort By" onChange={(e) => setSortDirection(e.target.value)}>
            {sortByDirection.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
          <label className='font-bold m-0'>Actions:</label>
          <Select
            style={{ backgroundColor: actionFilter }} className='text-black'
            labelId="activity-name-label" name="sortBy" value={actionFilter} label="Sort By" onChange={handleActionFilter}>
            {opsStatus.map((item) => (
              <MenuItem key={item.key} className='text-black' style={{ backgroundColor: item.key }} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </div>
        {/* <div className='flex items-center justify-start gap-4'>
          {filters.map(item => <button
            style={{ backgroundColor: item.color }}
            key={item.key}
            className='text-white px-4 py-2 rounded-lg w-40 text-center h-10'
            onClick={() => {
              setActive(item.key)
            }}
          >
            {item.key}
          </button>)}
        </div> */}
        <div className='grid grid-cols-1 gap-4'>
          {adminBookings}
        </div>
      </Container>
    </Page>
  );
};

const AdminBookingCard = ({ booking }) => {
  const { id,
    status,
    guestName,
    guestPhone,
    dateLabel,
    bookingDateLabel,
    amountTotal,
    amountFinal,
    amountAdvance,
    partner,
    bookingsSummary } = booking;

  return (
    <div
      style={{ boxShadow: `-12px 0 0px ${listBookingStatusColors[status]}` }}
      className='grid grid-cols-4 sm:grid-cols-6 bg-white p-4 rounded-lg gap-1 gap-y-2 shadow-md '>
      <div className='flex flex-col'>
        <p className='font-bold'>{guestName}</p>
        <p>{guestPhone}</p>
        <p className='mt-auto'>Id: <span className='font-bold'>{id}</span></p>
        {/* <p className='mt-auto'>Received: <span className='font-bold'>{bookingDateLabel}</span></p> */}
      </div>
      <div className='flex flex-col'>
        <p className='font-bold'>{dateLabel}</p>
        <p className='mt-auto'>Booked: <span className='font-bold'>{bookingDateLabel}</span></p>
      </div>
      {/* <p>{dateLabel}</p> */}
      <div className='flex flex-col'>
        <p className='font-bold'>{partner.title ?? "NA"}</p>
        <p>{bookingsSummary}</p>
      </div>
      <div className='grid grid-cols-2  place-self-center gap-x-2 gap-y-1' >
        <span className='font-bold'>Total:</span><p>{formatCurrency(amountTotal)}</p>
        <span className='font-bold'>Final:</span><p>{formatCurrency(amountFinal)}</p>
        <span className='font-bold'>Advance:</span><p>{formatCurrency(amountAdvance)}</p>
      </div>
      <div className='flex flex-col h-full items-center justify-center'>
        <button className=' border border-gray-400 rounded-lg min-h-full px-2 min-w-full'>{listBookingStatusLabels[status]}</button>
      </div>
      <Link
        className='bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded-lg w-40 text-center h-10 ml-auto'
        to={id} target="_blank" >
        View
      </Link>
    </div>
  );
}

AdminBookingCard.propTypes = {
  booking: PropTypes.object.isRequired,
};
export default ListBookingsPage;
