/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Page from '../components/Page';
import { format, addDays } from 'date-fns';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  ListItem,
  List,
  Container,
  Autocomplete,
} from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { LADAKH_PRESET, TAXI_TYPE } from 'src/data/ladakhPreset';

function LadakhItineraryPage() {
  const [guest, setGuest] = useState({});
  const [itinerary, setItinerary] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [pricingOptions, setPricingOptions] = useState({
    [TAXI_TYPE.EECO]: 0,
    [TAXI_TYPE.SCORPIO]: 0,
    [TAXI_TYPE.INNOVA]: 0,
    [TAXI_TYPE.CRYSTA]: 0,
    [TAXI_TYPE.TRAVELLER]: 0,
  });
  const [pricingBreakup, setPricingBreakup] = useState([]);

  const handlePresetSelected = async (_, newValue) => {
    try {
      const item = newValue.item;
      const newItinerary = [...itinerary, ...item.itinerary];
      const newPricingBreakup = pricingBreakup;
      newPricingBreakup.push({
        title: item.title,
        pricingOptions: item.pricingOptions,
      });

      const newPricingOptions = {
        [TAXI_TYPE.EECO]:
          pricingOptions[TAXI_TYPE.EECO] + item.pricingOptions[TAXI_TYPE.EECO],
        [TAXI_TYPE.SCORPIO]:
          pricingOptions[TAXI_TYPE.SCORPIO] +
          item.pricingOptions[TAXI_TYPE.SCORPIO],
        [TAXI_TYPE.INNOVA]:
          pricingOptions[TAXI_TYPE.INNOVA] +
          item.pricingOptions[TAXI_TYPE.INNOVA],
        [TAXI_TYPE.CRYSTA]:
          pricingOptions[TAXI_TYPE.CRYSTA] +
          item.pricingOptions[TAXI_TYPE.CRYSTA],
        [TAXI_TYPE.TRAVELLER]:
          pricingOptions[TAXI_TYPE.TRAVELLER] +
          item.pricingOptions[TAXI_TYPE.TRAVELLER],
      };
      setPricingOptions(newPricingOptions);
      setItinerary(newItinerary);
      setPricingBreakup(newPricingBreakup);
    } catch (error) {
      console.error('Error fetching activity variants:', error);
    }
  };

  const handleGuestDetailChange = (e) => {
    if (e.target.name === 'phone') {
      setGuest({ ...guest, [e.target.name]: e.target.value.replace(/\D/g, '') });
    } else {
      setGuest({ ...guest, [e.target.name]: e.target.value });
    }

  };

  const handleStartdateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleClear = async () => {
    setItinerary([]);
    setStartDate(null);
    setPricingOptions({
      [TAXI_TYPE.EECO]: 0,
      [TAXI_TYPE.SCORPIO]: 0,
      [TAXI_TYPE.INNOVA]: 0,
      [TAXI_TYPE.CRYSTA]: 0,
      [TAXI_TYPE.TRAVELLER]: 0,
    });
    setPricingBreakup([]);
  };

  const handleSave = async () => {
    const jsStartDate = new Date(startDate);
    const request = {
      user: {
        phone: `91${guest.phone}`,
        name: guest.name,
      },
      activityId: 'al32py1',
      itinerary: itinerary,
      pricingOptions: [
        {
          variantId: 'al32py2',
          variantTitle: 'Economy Class Taxis',
          variantDesc: 'EECO',
          pricing: [{ pricingId: '47l0ym', quantity: 1, title: 'Car' }],
          price: pricingOptions[TAXI_TYPE.EECO],
          priceLabel: `₹ ${pricingOptions[TAXI_TYPE.EECO]}`,
        },
        {
          variantId: 'al32py3',
          variantTitle: 'Comfort Class Taxis',
          variantDesc: 'Scorpio / Xylo / Ertiga',
          pricing: [{ pricingId: '4l4fcx', quantity: 1, title: 'Car' }],
          price: pricingOptions[TAXI_TYPE.SCORPIO],
          priceLabel: `₹ ${pricingOptions[TAXI_TYPE.SCORPIO]}`,
        },
        {
          variantId: 'al32py4',
          variantTitle: 'Premium Class Taxis',
          variantDesc: 'Innova',
          pricing: [{ pricingId: 'tba7i6', quantity: 1, title: 'Car' }],
          price: pricingOptions[TAXI_TYPE.INNOVA],
          priceLabel: `₹ ${pricingOptions[TAXI_TYPE.INNOVA]}`,
        },
        {
          variantId: 'al32py6',
          variantTitle: 'Luxury Class Taxi',
          variantDesc: 'Innova Crysta',
          pricing: [{ pricingId: '4agt3i', quantity: 1, title: 'Car' }],
          price: pricingOptions[TAXI_TYPE.CRYSTA],
          priceLabel: `₹ ${pricingOptions[TAXI_TYPE.CRYSTA]}`,
        },
        {
          variantId: 'al32py8',
          variantTitle: 'Tempo Traveller',
          variantDesc: 'Tempo Traveller',
          pricing: [{ pricingId: '4agt36', quantity: 1, title: 'Car' }],
          price: pricingOptions[TAXI_TYPE.TRAVELLER] * 1.075,
          priceLabel: `₹ ${pricingOptions[TAXI_TYPE.TRAVELLER] * 1.075}`,
        },
      ],
      pricingBreakup,
      startDate,
      endDate: startDate
        ? format(
          addDays(new Date(startDate), itinerary.length - 1),
          'yyyy-MM-dd'
        )
        : undefined,
    };

    const response = await axios.post(`${API_URL}/curatedItinerary`, request);
    const newUrl = `https://www.passprt.co/curated/${response.data.slug}`;
    window.open(newUrl, '_blank');
    window.location.reload();
  };

  return (
    <Page title="Master Inventory">
      <Container>
        <div style={{ width: '100%', display: 'inline' }}>
          <div
            style={{
              width: '30%',
              margin: '0px 20px 20px 0px',
              display: 'inline-grid',
            }}
          >
            <label>Name</label>
            <input
              onChange={handleGuestDetailChange}
              name="name"
              value={guest.name}
            />
          </div>

          <div
            style={{
              width: '30%',
              margin: '0px 20px 0px 0px',
              display: 'inline-grid',
            }}
          >
            <label>Phone</label>
            <input
              onChange={handleGuestDetailChange}
              name="phone"
              value={guest.phone}
              type="text"
            />
          </div>
          <div
            style={{
              width: '30%',
              margin: '0px 20px 0px 0px',
              display: 'inline-grid',
            }}
          >
            <label>Date</label>
            <input
              onChange={handleStartdateChange}
              name="startDate"
              value={startDate}
              type="date"
            />
          </div>
        </div>
        <Autocomplete
          id="combo-box-demo"
          options={LADAKH_PRESET.map((lp, index) => ({
            id: index,
            label: lp.title,
            item: lp,
          }))}
          sx={{ width: 340 }}
          renderInput={(params) => <TextField {...params} />}
          onChange={handlePresetSelected}
        />
        {itinerary.map((iti, itiIndex) => (
          <div style={{ paddingTop: '20px' }}>
            <Typography variant="h6" component="h2">
              Day {itiIndex + 1} -{' '}
              {format(addDays(new Date(startDate), itiIndex), 'd MMMM')}
            </Typography>
            <Typography variant="body1">{iti.title}</Typography>
          </div>
        ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingTop: '40px',
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ margin: 'auto', marginTop: 4, boxShadow: 3 }}
          >
            <Typography
              variant="h6"
              sx={{
                p: 2,
                color: 'primary.main',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              Pricing
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>{TAXI_TYPE.EECO}</TableCell>
                  <TableCell>{TAXI_TYPE.SCORPIO}</TableCell>
                  <TableCell>{TAXI_TYPE.INNOVA}</TableCell>
                  <TableCell>{TAXI_TYPE.CRYSTA}</TableCell>
                  <TableCell>{TAXI_TYPE.TRAVELLER}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingBreakup.length ? (
                  <>
                    {pricingBreakup.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>
                          {row.pricingOptions[TAXI_TYPE.EECO]}
                        </TableCell>
                        <TableCell>
                          {row.pricingOptions[TAXI_TYPE.SCORPIO]}
                        </TableCell>
                        <TableCell>
                          {row.pricingOptions[TAXI_TYPE.INNOVA]}
                        </TableCell>
                        <TableCell>
                          {row.pricingOptions[TAXI_TYPE.CRYSTA]}
                        </TableCell>
                        <TableCell>
                          {row.pricingOptions[TAXI_TYPE.TRAVELLER]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                <TableRow key={'total'}>
                  <TableCell component="th" scope="row">
                    <b>Total</b>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <b>{pricingOptions[TAXI_TYPE.EECO]}</b>
                  </TableCell>
                  <TableCell>
                    <b>{pricingOptions[TAXI_TYPE.SCORPIO]}</b>
                  </TableCell>
                  <TableCell>
                    <b>{pricingOptions[TAXI_TYPE.INNOVA]}</b>
                  </TableCell>
                  <TableCell>
                    <b>{pricingOptions[TAXI_TYPE.CRYSTA]}</b>
                  </TableCell>
                  <TableCell>
                    <b>{pricingOptions[TAXI_TYPE.TRAVELLER]}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* Repeat for more days */}
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Create
        </Button>
        <Button
          style={{ marginLeft: '20px' }}
          onClick={handleClear}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Clear
        </Button>
      </Container>
    </Page>
  );
}

export default LadakhItineraryPage;
